import React from 'react';
import PropTypes from 'prop-types';
import Modal from '../common/Modal/Modal';
import { NavLink } from 'react-router-dom';
import Notification from '../Notification/Notification';
import './Header.css';

const Header = ({ userName, logout, ...props }) => (
  <div className="navbar-omni navbar navbar-expand-lg navbar-light">
    <div className="omni-logo navbar-brand"></div>
    <div className="seperator" />
    <ul className="links-container navbar-nav mr-auto">
      <li className="nav-item p-2">
        <NavLink to="/dashboard" className="nav-link">
          DASHBOARD
        </NavLink>
      </li>

      <li className="nav-item p-2">
        <NavLink to="/map" className="nav-link">
          MAP
        </NavLink>
      </li>
    </ul>
    <div className="notification-container mr-auto">
      <Notification {...props} />
    </div>
    <div className="user-container d-flex">
      <div className="user-info d-flex">
        <i className="material-icons">person</i>
        <div>{userName}</div>
        <div className="dropdown">
          <span
            id="dropdownMenuButton"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            className="material-icons">
            keyboard_arrow_down
          </span>
          <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
            {/* <a className="dropdown-item p-2" href="/about">
              <span class="material-icons">info</span>
              About
            </a> */}
            <a
              className="dropdown-item p-2"
              href="#"
              data-toggle="modal"
              data-target="#logout-modal">
              <span className="material-icons">exit_to_app</span>
              Logout
            </a>
          </div>
        </div>
      </div>
    </div>
    <Modal
      uniqueId="logout-modal"
      headerTitle='Logout'
      buttons={[
        { name: 'Yes', clickHandler: logout, className: 'danger danger-submit-btn' },
        { name: 'No', className: 'danger danger-submit-btn' },
      ]}
      bodyClass="modal-body modal-body-logout">
      Are you sure you want to logout?
    </Modal>
  </div>
);

Header.propTypes = {
  userName: PropTypes.string.isRequired,
};

export default React.memo(Header);
