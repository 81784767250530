import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';

import './BroadcastSettings.css';

import { APIs } from '../../constants/endPointsURLs';

import NavigationTabsContainer from '../common/NavigationTabsContainer/NavigationTabsContainer';
import SettingsControl from '../SettingsControl/SettingsControl';
import Export from '../Export/Export';
import Records from '../Records/Records';

class BroadcastSettings extends React.PureComponent {
  state = {
    isExportDisplay: false,
    isExistExport: false,
  };

  setExportDisplay = (isExportDisplay) => {
    this.setState({ ...this.state, isExportDisplay });
  };

  setIsExistExport = (isExistExport) => {
    this.setState({ ...this.state, isExistExport });
  };

  updateSocialMediaSettings = (type, url) => {
    this.setState({
      socialMediaSettings: { type: type, url: url },
    });

    return axios({
      method: 'post',
      url: APIs.modifySocialMediaSettings(this.props.focusedPrimaryId),
      data: {
        type,
        url,
      },
      withCredentials: true,
    });
  };

  render() {
    const {
      outputs,
      isPrimaryFocused,
      focusedPrimaryId,
      isPrimaryBroadcasting,
      broadcastSettings,
      areBroadcastSettingsLoading,
      arePrimaryRecordsLoading,
      socialMediaSettings,
      primaryRecords,
      clickSettingsApply,
      associatePrimaryWithOutput,
      handleStartRecordUpload,
      handlePauseRecordUpload,
      setDetailsForDeleteRecordModal,
      screenSize,
      showNotification,
      companyType,
      setExportModalData,
      primaryName,
      isFreeTrial,
      isBasic,
      isLiveOmni,
    } = this.props;

    if (!focusedPrimaryId) return null;

    return (
      <NavigationTabsContainer
        companyType={companyType}
        tabsContainerClass="broadcast-settings col-4 broadcast-settings-padding"
        tabsContainerId="broadcastSettings"
        focusedPrimaryId={focusedPrimaryId}
        setExportDisplay={this.setExportDisplay}
      >
        <SettingsControl
          componentId="settings-control"
          tabName="Stream"
          tabIcon="videocam"
          isPrimaryBroadcasting={isPrimaryBroadcasting}
          broadcastSettings={broadcastSettings}
          areBroadcastSettingsLoading={areBroadcastSettingsLoading}
          socialMediaSettings={socialMediaSettings}
          clickSettingsApply={clickSettingsApply}
          focusedPrimaryId={focusedPrimaryId}
          associatePrimaryWithOutput={associatePrimaryWithOutput}
          updateSocialMediaSettings={this.updateSocialMediaSettings}
          outputs={outputs}
          screenSize={screenSize}
          companyType={companyType}
        />

        <Export
          componentId="Export"
          tabName="Export"
          tabIcon="open_in_new"
          isExistExport={this.state.isExistExport}
          isExportDisplay={this.state.isExportDisplay}
          focusedPrimaryId={focusedPrimaryId}
          broadcastSettingsCodec={broadcastSettings.codec}
          socialMediaSettings={socialMediaSettings}
          updateSocialMediaSettings={this.updateSocialMediaSettings}
          showNotification={showNotification}
          companyType={companyType}
          setExportModalData={setExportModalData}
          primaryName={primaryName}
          setIsExistExport={this.setIsExistExport}
          setOmniExport={this.setOmniExport}
          broadcastSettings={broadcastSettings}
          clickSettingsApply={clickSettingsApply}
          isPrimaryBroadcasting={isPrimaryBroadcasting}
          isFreeTrial={isFreeTrial}
          isBasic={isBasic}
          isLiveOmni={isLiveOmni}
        />
        {companyType === 0 && (
          <Records
            componentId="records"
            tabName="Records"
            tabIcon="video_library"
            records={primaryRecords}
            handleStartUpload={handleStartRecordUpload}
            handlePauseUpload={handlePauseRecordUpload}
            setDetailsForDeleteRecordModal={setDetailsForDeleteRecordModal}
            areRecordsLoading={arePrimaryRecordsLoading}
            isPrimaryFocused={isPrimaryFocused}
          />
        )}
      </NavigationTabsContainer>
    );
  }
}

BroadcastSettings.propTypes = {
  focusedPrimaryId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  broadcastSettings: PropTypes.object.isRequired,
};

export default BroadcastSettings;
