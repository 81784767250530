import React, { Fragment } from 'react';
import $ from 'jquery';
import axios from 'axios';
import { EXPORT_METHODS } from '../Export/Export';
import { EXPORT_APIS } from '../../constants/endPointsURLs';
import isEqual from 'lodash/isEqual';
import dashboardConfig from '../../dashboard_config.json';
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  TelegramShareButton,
  EmailShareButton,
} from 'react-share';
import {
  FacebookIcon,
  FacebookMessengerIcon,
  TwitterIcon,
  TelegramIcon,
  WhatsappIcon,
  LinkedinIcon,
  EmailIcon,
} from 'react-share';
import './Modal.css';
import { isExistUser } from '../../utils/codecHandlers';

const copyIcon = './images/copy-icon-round.svg';

class Modal extends React.Component {
  constructor(props) {
    super(props);
    this.handleHideModalClose = this.handleHideModalClose.bind(this);
    this.handleDeleteModal = this.handleDeleteModal.bind(this);
    this.state = {
      currentOption: '',
      currentDisabled: false,
      formFields: {},
      exportName: '',
      focusedPrimaryId: '',
      disabled: false,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.currentOption === prevState.currentOption) {
      if (
        this.state.currentOption === 'RTMP' ||
        'SRTCaller' ||
        'OmnistreamLivePlayerRTMP' ||
        'OmnistreamLivePlayerSRT'
      ) {
        if (!isEqual(this.state.formFields, prevState.formFields)) {
          this.state.currentDisabled === true &&
            this.setState({ currentDisabled: false });
        }
      }
    }
    if (this.props.exportButtonRole.id === 'create-export')
      this.checkRequiredFields(this.props.exportButtonRole.currentOption);
  }

  componentDidMount() {
    const { handleHideModal, focusedPrimaryId, exportButtonRole } = this.props;
    this.setState({ focusedPrimaryId });
    $(this.modal).modal('show');
    $(this.modal).on('hidden.bs.modal', handleHideModal);
    if (
      exportButtonRole.id === 'create-export' &&
      exportButtonRole.currentOption !== 'SRTListener' &&
      exportButtonRole.currentOption !== 'OmnistreamLivePlayerRTMP' &&
      exportButtonRole.currentOption !== 'OmnistreamLivePlayerSRT'
    ) {
      this.setState({ disabled: true });
    }
  }
  handleHideModalClose(isEdit) {
    const { handleHideModal } = this.props;
    $(this.modal).modal('hide');
    handleHideModal(isEdit);
  }

  handleDeleteModal() {
    const { handleHideModal, exportsItemId, handleDelete, deleteLabelItem } =
      this.props;
    $(this.modal).modal('hide');
    handleHideModal();
    handleDelete(exportsItemId);
    deleteLabelItem(exportsItemId);
  }

  handleDeleteLabel = () => {
    const { handleHideModal, exportsItemId, deleteLabelItem } = this.props;
    $(this.modal).modal('hide');
    handleHideModal();
    deleteLabelItem(exportsItemId);
  };

  handleDeleteAllExports = () => {
    const { handleHideModal } = this.props;
    $(this.modal).modal('hide');
    handleHideModal();
    this.deleteAllExports(this.state.focusedPrimaryId);
  };

  deleteAllExports = async (primaryId) => {
    try {
      const response = await axios({
        method: 'delete',
        url:
          EXPORT_APIS.deleteAllExports(primaryId) +
          `?keepAmsExports=${
            dashboardConfig.dashboard.exports.keepAmsExports &&
            (this.props.isFreeTrial || this.props.isBasic || this.props.isLiveOmni)
          }`,
        withCredentials: true,
      });
      if (response.status === 200 || response.status === 201) {
        this.props.fetchItems(false, this.state.focusedPrimaryId);
        const output = this.props.primaryUsers.find(
          (output) => output.id === this.state.focusedPrimaryId
        );
        const nofificationMessage = `All exports was successfully deleted for primary ${this.props.primaryName}`;
        if (output.isActive) {
          this.props.checkIsExistOmniSRT(
            'state',
            nofificationMessage,
            'delete-all'
          );
        } else {
          this.props.showNotification('deleAllExports', nofificationMessage);
        }
      }
    } catch (e) {
      this.props.showNotification('deleAllExports', e.response.data.message);
    }
  };

  handleInputChange = (e) => {
    let value = e.target.value;
    let formFields = { ...this.state.formFields };
    formFields[e.target.id] = value;
    this.setState({ formFields });
  };

  handleChangeExport = (exportRole) => {
    this.setState({ currentDisabled: true });
    this.props.setShowEditModal(true);
    this.handleExport(exportRole);
  };

  defineTypeExport = (field) => {
    const typeExport = {
      id: '',
      name: '',
      buttonType: '',
    };
    switch (field) {
      case 'RTMP':
        return {
          ...typeExport,
          id: 'RTMP',
          name: 'Custom RTMP',
          buttonType: 'Apply',
        };
      case 'SRTCaller':
        return {
          ...typeExport,
          id: 'SRTCaller',
          name: 'SRT Caller',
          buttonType: 'Apply',
        };
      case 'SRTListener':
        return {
          ...typeExport,
          id: 'SRTListener',
          name: 'SRT Listener',
          buttonType: 'Generate',
        };
      case 'OmnistreamLivePlayerRTMP':
        return {
          ...typeExport,
          id: 'OmnistreamLivePlayerRTMP',
          name: 'LIVE OMNI - RTMP',
          buttonType: 'Generate',
        };
      case 'OmnistreamLivePlayerSRT':
        return {
          ...typeExport,
          id: 'OmnistreamLivePlayerSRT',
          name: 'LIVE OMNI - SRT',
          buttonType: 'Generate',
        };
      default:
        return null;
    }
  };

  handleOpenVideo = (videoUrl) => {
    const modal_content = document.getElementById('modal_content');
    const modal_dialog = document.getElementById('modal_dialog');
    while (modal_content.firstChild) {
      modal_content.removeChild(modal_content.firstChild);
    }
    modal_content.classList.add('modal-content-video');
    modal_dialog.classList.add('modal-dialog-video');
    modal_dialog.classList.add('modal-dialog-centered');

    modal_content.innerHTML += `<div class="livePlayer-text-container"><div class="livePlayer-text">Omnistream Live Player Pro <button class="close-modal-btn-default" data-dismiss="modal"> &times;</button></div></div>`;
    modal_content.innerHTML += `<iframe width="750" height="400" src="${videoUrl}" frameborder="0" allowfullscreen></iframe>`;
  };

  handleOpenNewTab = (videoUrl) => {
    window.open(
      videoUrl,
      '_blank',
      `toolbar=no,scrollbars=no,resizable=yes,width=${window.screen.availWidth},height=${window.screen.availHeight},fullscreen=yes`
    );
    this.handleHideModalClose();
  };

  renderBodyModal = () => {
    const {
      exportButtonRole,
      exportList,
      exportsItemId,
      exportName,
      labelValue,
      handleCopyField,
      primaryName,
      videoUrl,
    } = this.props;

    if (exportButtonRole.id === 'delete') {
      return (
        <Fragment>
          <div className="modal-header header-container dialog-pad-top">
            <h6 className="modal-title" id="modalLabel">
              Are you sure you want to delete {exportName} export?
            </h6>
          </div>
          <div className="modal-footer dialog-pad-bottom">
            <button
              type="button"
              className="btn btn-primary dialog-button"
              onClick={this.handleDeleteModal}
            >
              Yes
            </button>
            <button
              type="button"
              className="btn btn-primary dialog-button"
              onClick={this.handleHideModalClose}
            >
              No
            </button>
          </div>
        </Fragment>
      );
    }

    if (exportButtonRole.id === 'copy') {
      const handleCopy = (e) => {
        const type = e.target.id;
        if (type === 'vmix/obs') {
          const url = labelValue.replace(/mode=listener/g, 'mode=caller');
          handleCopyField(url, `${exportName} URL`);
          this.handleHideModalClose();
        } else {
          handleCopyField(labelValue, `${exportName} URL`);
          this.handleHideModalClose();
        }
      };

      return (
        <Fragment>
          <div className="modal-header header-container dialog-pad-top">
            <h5 className="modal-title " id="modalLabel">
              Copy SRT Listener address
            </h5>
          </div>
          <div className="modal-footer dialog-pad-bottom">
            <button
              id="vmix/obs"
              type="button"
              className="btn btn-primary dialog-button"
              onClick={handleCopy}
            >
              VMIX / OBS
            </button>
            <button
              id="url"
              type="button"
              className="btn btn-primary dialog-button"
              onClick={handleCopy}
            >
              URL
            </button>
          </div>
        </Fragment>
      );
    }

    if (exportButtonRole.id === 'delete-all-exports') {
      return (
        <Fragment>
          <div className="modal-header header-container dialog-pad-top">
            <h5 className="modal-title " id="modalLabel">
              Delete All Exports?
            </h5>
          </div>
          <div className="modal-body text-center">
            <h6>
              Are you sure you want to delete all exports for primary{' '}
              <span className="cap-letter">{`${primaryName}?`}</span>
            </h6>
          </div>
          <div className="modal-footer dialog-pad-bottom">
            <button
              type="button"
              className="btn btn-primary dialog-button"
              onClick={this.handleDeleteAllExports}
            >
              Yes
            </button>
            <button
              type="button"
              className="btn btn-primary dialog-button"
              onClick={this.handleHideModalClose}
            >
              No
            </button>
          </div>
        </Fragment>
      );
    }

    if (exportButtonRole.id === 'play_video') {
      return (
        <Fragment>
          <div className="modal-header header-container dialog-pad-top">
            <h5 className="modal-title " id="modalLabel">
              Choose how to play video player?
            </h5>
          </div>
          <div className="modal-footer dialog-pad-bottom">
            <button
              id="modal"
              type="button"
              className="btn btn-primary dialog-button"
              style={{ minWidth: '150px' }}
              onClick={() => this.handleOpenVideo(videoUrl)}
            >
              Dashboard View
            </button>
            <button
              id="new_window"
              type="button"
              target="_blank"
              className="btn btn-primary dialog-button"
              style={{ minWidth: '150px' }}
              onClick={() => this.handleOpenNewTab(videoUrl)}
            >
              New Tab
            </button>
          </div>
        </Fragment>
      );
    }

    if (exportButtonRole.id === 'share_video') {
      const shareMessage = `Join and watch my live stream on Omni Live: ${videoUrl}`;
      const WhatsAppMessage = `Join and watch my live stream on Omni Live:%0A${videoUrl}`;
      const WhatsAppLink = `whatsapp://send?text=${WhatsAppMessage}`;
      const fbMessengerLink = `https://www.facebook.com/dialog/send?link=${videoUrl}&app_id=385274096712337&redirect_uri=${window.location.href}`;

      return (
        <Fragment>
          <div className="modal-header header-container dialog-pad-top">
            <h5 className="modal-title " id="modalLabel">
              Share Your Live Stream
            </h5>
          </div>
          <div className="modal_media_container">
            <FacebookShareButton url={videoUrl}>
              <FacebookIcon size={40} round />
            </FacebookShareButton>
            <div className="copy_icon_rounded">
              <FacebookMessengerIcon
                onClick={() => window.open(fbMessengerLink, '_blank')}
                size={40}
                round
              />
            </div>
            <TwitterShareButton url={shareMessage}>
              <TwitterIcon size={40} round />
            </TwitterShareButton>
            <LinkedinShareButton url={videoUrl}>
              <LinkedinIcon size={40} round />
            </LinkedinShareButton>
            <div className="copy_icon_rounded">
              <WhatsappIcon
                onClick={() => window.open(WhatsAppLink, '_blank')}
                size={40}
                round
              />
            </div>
            <TelegramShareButton url={shareMessage}>
              <TelegramIcon size={40} round />
            </TelegramShareButton>
            <EmailShareButton url={shareMessage}>
              <EmailIcon size={40} round />
            </EmailShareButton>
            <div className="copy_icon_rounded">
              <img
                alt="copy"
                src={copyIcon}
                onClick={() => handleCopyField(videoUrl, 'Live Stream URL')}
              ></img>
            </div>
          </div>
          <div className="modal-footer dialog-pad-bottom">
            <button
              id="modal"
              type="button"
              className="btn btn-primary dialog-button"
              style={{ minWidth: '150px' }}
              onClick={this.handleHideModalClose}
            >
              Close
            </button>
          </div>
        </Fragment>
      );
    }

    if (exportButtonRole.id === 'create-export') {
      const { currentOption } = exportButtonRole;
      const typeExportFields = EXPORT_METHODS.find(
        (method) => method.id === currentOption
      ).fields;
      const { currentDisabled } = this.state;
      const exportData = this.defineTypeExport(exportButtonRole.currentOption);
      if (this.state.currentOption !== currentOption) {
        this.setState({ currentOption: currentOption });
      }
      return (
        <Fragment>
          <div className="form-container p-3 pt-0 form-padd">
            <div className="modal-header header-container edit-container">
              <h5 className="modal-title" id="modalLabel">
                Create New {exportData.name} Export
              </h5>
            </div>

            {typeExportFields.map((field) => (
              <Fragment key={field.name}>
                {(currentOption === 'SRTCaller' ||
                  currentOption === 'OmnistreamLivePlayerSRT') &&
                (field.id === 'port' || field.id === 'exportStreamId') ? (
                  <>
                    {field.id === 'port' && (
                      <div className="SRTCaller-input-container">
                        {typeExportFields
                          .filter(
                            (elem) =>
                              elem.id === 'port' || elem.id === 'exportStreamId'
                          )
                          .map((field) => (
                            <Fragment key={field.name}>
                              <div className="SRTCaller-input">
                                <label className="col-form-label">
                                  <div
                                    className={`modal-field-label ${
                                      field.required &&
                                      'modal-field-label-required'
                                    }`}
                                  >
                                    {field.name}
                                    {field.required ? (
                                      <span className="modal-field-icon-star">
                                        *
                                      </span>
                                    ) : null}
                                  </div>
                                </label>
                                <div className="input-group input-group-sm mb-3">
                                  <input
                                    id={field.id}
                                    className={`form-control input-custom ${
                                      field.id === 'key' && 'input-custom-key'
                                    }`}
                                    onChange={this.handleInputChange}
                                    value={
                                      this.state.formFields[field.id] || ''
                                    }
                                    disabled={field.disabled || currentDisabled}
                                  />
                                  <button
                                    className="p2 copy-btn material-icons export_btns_padd copy_btn_pad copy-btn-input"
                                    id="copy"
                                    value={field.id.toString()}
                                    onClick={() =>
                                      handleCopyField(
                                        this.state.formFields[field.id],
                                        field.name
                                      )
                                    }
                                  >
                                    content_copy
                                  </button>
                                </div>
                              </div>
                            </Fragment>
                          ))}
                      </div>
                    )}
                  </>
                ) : (
                  <>
                    <label className="col-form-label">
                      <div
                        className={`modal-field-label ${
                          field.required && 'modal-field-label-required'
                        }`}
                      >
                        {field.name}
                        {field.required ? (
                          <span className="modal-field-icon-star">*</span>
                        ) : null}
                      </div>
                    </label>
                    <div className="input-group input-group-sm mb-3">
                      <input
                        id={field.id}
                        className={`form-control input-custom ${
                          field.id === 'key' && 'input-custom-key'
                        }`}
                        onChange={this.handleInputChange}
                        value={this.state.formFields[field.id] || ''}
                        disabled={field.disabled || currentDisabled}
                      />
                      <button
                        className="p2 copy-btn material-icons export_btns_padd copy_btn_pad copy-btn-input"
                        id="copy"
                        value={field.id.toString()}
                        onClick={() =>
                          handleCopyField(
                            this.state.formFields[field.id],
                            field.name
                          )
                        }
                      >
                        content_copy
                      </button>
                    </div>
                  </>
                )}
              </Fragment>
            ))}
            <div className="modal-footer-buttons-container">
              <button
                disabled={this.state.disabled}
                type="button"
                className="btn btn-primary dialog-button"
                onClick={() => this.handleExport('create-export')}
              >
                {exportData.buttonType}
              </button>
              <button
                type="button"
                className="btn btn-primary dialog-button"
                onClick={this.handleHideModalClose}
              >
                {(currentOption === 'SRTListener' ||
                  currentOption === 'OmnistreamLivePlayerRTMP' ||
                  currentOption === 'OmnistreamLivePlayerSRT') &&
                this.state.disabled
                  ? 'Save & Close'
                  : 'Cancel'}
              </button>
            </div>
          </div>
        </Fragment>
      );
    }

    if (exportButtonRole.id === 'delete-label') {
      return (
        <Fragment>
          <div className="modal-header header-container dialog-pad-top">
            <h6 className="modal-title" id="modalLabel">
              Are you sure you want to delete{' '}
              <span className="cap-letter">"{labelValue}"</span> label for
              <br></br> {exportName} export?
            </h6>
          </div>
          <div className="modal-footer dialog-pad-bottom">
            <button
              type="button"
              className="btn btn-primary dialog-button"
              onClick={this.handleDeleteLabel}
            >
              Yes
            </button>
            <button
              type="button"
              className="btn btn-primary dialog-button"
              onClick={this.handleHideModalClose}
            >
              No
            </button>
          </div>
        </Fragment>
      );
    }
    if (exportButtonRole.id === 'edit') {
      const exportItem = exportList.find((item) => item.id === exportsItemId);
      if (exportItem.protocol === 'rtmp') {
        if (
          this.state.currentOption !== 'OmnistreamLivePlayerRTMP' &&
          exportItem.type === 7
        ) {
          exportItem['url'] = `rtmp://${exportItem.host}${
            exportItem.port ? `:${exportItem.port}` : ''
          }${exportItem.endpoint ? `/${exportItem.endpoint}` : ''}`;
          this.setState({
            currentOption: 'OmnistreamLivePlayerRTMP',
            formFields: exportItem,
            exportName: 'Omnistream Live Player',
            currentDisabled: false,
          });
        } else if (
          this.state.currentOption !== 'RTMP' &&
          exportItem.type === 0
        ) {
          exportItem['url'] = `rtmp://${exportItem.host}${
            exportItem.port ? `:${exportItem.port}` : ''
          }${exportItem.endpoint ? `/${exportItem.endpoint}` : ''}`;
          this.setState({
            currentOption: 'RTMP',
            formFields: exportItem,
            exportName: 'Custom RTMP',
            currentDisabled: true,
          });
        }
      }
      if (exportItem.protocol === 'rtmps') {
        if (this.state.currentOption !== 'RTMP') {
          exportItem['url'] = `rtmps://${exportItem.host}${
            exportItem.port ? `:${exportItem.port}` : ''
          }${exportItem.endpoint ? `/${exportItem.endpoint}` : ''}`;
          this.setState({
            currentOption: 'RTMP',
            formFields: exportItem,
            exportName: 'Custom RTMP',
            currentDisabled: true,
          });
        }
      }
      if (exportItem.protocol === 'srt' && exportItem.type === 1) {
        if (this.state.currentOption !== 'SRTCaller')
          this.setState({
            currentOption: 'SRTCaller',
            formFields: exportItem,
            exportName: 'SRT Caller',
            currentDisabled: true,
          });
      }
      if (exportItem.protocol === 'srt' && exportItem.type === 2) {
        if (this.state.currentOption !== 'SRTListener')
          this.setState({
            currentOption: 'SRTListener',
            formFields: exportItem,
            exportName: 'SRT Listener',
            currentDisabled: false,
          });
      }
      const typeExportFields =
        this.state.currentOption &&
        EXPORT_METHODS.find((method) => method.id === this.state.currentOption)
          .fields;
      const form = this.state.currentOption && (
        <div className="form-container p-3 pt-0 form-padd">
          <div className="modal-header header-container edit-container">
            {/* <button type="button" className="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button> */}
            <h5 className="modal-title" id="modalLabel">
              Change settings {this.state.exportName}
            </h5>
          </div>
          {typeExportFields.map((field) => (
            <Fragment key={field.name}>
              {this.state.currentOption === 'SRTCaller' &&
              (field.id === 'port' || field.id === 'exportStreamId') ? (
                <>
                  {field.id === 'port' && (
                    <div className="SRTCaller-input-container">
                      {typeExportFields
                        .filter(
                          (elem) =>
                            elem.id === 'port' || elem.id === 'exportStreamId'
                        )
                        .map((field) => (
                          <Fragment key={field.name}>
                            <div className="SRTCaller-input">
                              <label className="col-form-label">
                                {field.name}
                              </label>
                              <div className="input-group input-group-sm mb-3">
                                <input
                                  id={field.id}
                                  className={`form-control input-custom ${
                                    field.id === 'key' && 'input-custom-key'
                                  }`}
                                  onChange={this.handleInputChange}
                                  value={this.state.formFields[field.id] || ''}
                                  disabled={
                                    this.state.currentOption ===
                                      'SRTListener' ||
                                    this.state.currentOption ===
                                      'OmnistreamLivePlayerRTMP'
                                      ? field.disabled ||
                                        this.state.currentDisabled
                                      : false
                                  }
                                />
                                <button
                                  className="p2 copy-btn material-icons export_btns_padd copy_btn_pad copy-btn-input"
                                  id="copy"
                                  value={field.id.toString()}
                                  onClick={() =>
                                    handleCopyField(
                                      this.state.formFields[field.id],
                                      field.name
                                    )
                                  }
                                >
                                  content_copy
                                </button>
                              </div>
                            </div>
                          </Fragment>
                        ))}
                    </div>
                  )}
                </>
              ) : (
                <>
                  <label className="col-form-label">{field.name}</label>
                  <div className="input-group input-group-sm mb-3">
                    <input
                      id={field.id}
                      className={`form-control input-custom ${
                        field.id === 'key' && 'input-custom-key'
                      }`}
                      onChange={this.handleInputChange}
                      value={this.state.formFields[field.id] || ''}
                      disabled={
                        this.state.currentOption === 'SRTListener' ||
                        this.state.currentOption === 'OmnistreamLivePlayerRTMP'
                          ? field.disabled || this.state.currentDisabled
                          : false
                      }
                    />
                    <button
                      className="p2 copy-btn material-icons export_btns_padd copy_btn_pad copy-btn-input"
                      id="copy"
                      value={field.id.toString()}
                      onClick={() =>
                        handleCopyField(
                          this.state.formFields[field.id],
                          field.name
                        )
                      }
                    >
                      content_copy
                    </button>
                  </div>
                </>
              )}
            </Fragment>
          ))}
          <div className="dialog-button-edit-container">
            {this.state.currentOption === 'SRTListener' ||
            this.state.currentOption === 'OmnistreamLivePlayerRTMP' ? (
              <button
                disabled={this.state.currentDisabled}
                type="button"
                className="btn btn-info m-2 d-flex justify-content-center align-items-center dialog-button-edit"
                onClick={this.handleChangeExport}
              >
                Regenerate
              </button>
            ) : (
              <button
                disabled={this.state.currentDisabled}
                type="button"
                title="Please change export settings in order to save"
                alt="Please change export settings in order to save"
                className="btn btn-info m-2 d-flex justify-content-center align-items-center dialog-button-edit"
                onClick={() => this.handleChangeExport('edit')}
              >
                {'Save & Close'}
              </button>
            )}
            <button
              type="button"
              className="btn btn-info m-2 d-flex justify-content-center align-items-center dialog-button-edit"
              onClick={this.handleHideModalClose}
            >
              {this.state.currentOption === 'SRTListener' ||
              this.state.currentOption === 'OmnistreamLivePlayerRTMP'
                ? 'Close'
                : 'Cancel'}
            </button>
          </div>
        </div>
      );
      return form;
    }
  };

  generateAMSExport = async (config) => {
    try {
      const data = {
        exportStreamId: '',
        isEnabled: true,
        protocol: config.protocol,
        src: 0,
        type: config.type,
      };

      const response = await axios({
        method: 'post',
        url: config.url,
        withCredentials: true,
        data,
      });

      if (response.status === 200) {
        const isExistUserId = isExistUser(this.state.focusedPrimaryId);
        if (!isExistUserId) {
          // If we create export type 7 or 8 then we should update codec type to H.264
          this.props.handleUpdateCodecType('H.264');
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  getAMSExportInfo = async (focusedPrimaryId, protocol) => {
    try {
      const response = await axios({
        method: 'get',
        url: EXPORT_APIS.getAMSExport(focusedPrimaryId),
        withCredentials: true,
      });

      const amsExportProtocol = response.data.amsExportProtocol;
      if (!amsExportProtocol) {
        await this.generateAMSExport(
          this.checkTypeAMSExportAPI(protocol, focusedPrimaryId)
        );
      } else if (amsExportProtocol.toLowerCase() !== protocol) {
        await this.generateAMSExport(
          this.checkTypeAMSExportAPI(protocol, focusedPrimaryId)
        );
      }
    } catch (e) {
      console.log(e);
    }
  };

  checkTypeAMSExportAPI = (protocol, focusedPrimaryId) => {
    switch (protocol) {
      case 'rtmp':
      case 'rtmps':
        return {
          url: EXPORT_APIS.generateExportRTMP(focusedPrimaryId),
          type: 7,
          protocol,
        };
      case 'srt':
        return {
          url: EXPORT_APIS.generateExportSRT(focusedPrimaryId),
          type: 8,
          protocol,
        };
      default:
        return '';
    }
  };

  handleExport = async (exportRole) => {
    const { exportsItemId, handleDelete } = this.props;

    const currentOption = EXPORT_METHODS.find(
      (method) => method.id === this.state.currentOption
    );
    const [firstField, secondField, thirdField, fourthField] =
      currentOption.fields;
    let reqData = {};
    reqData['protocol'] = 'srt';

    if (
      (firstField && !this.parseField(firstField, reqData)) ||
      (secondField && !this.parseField(secondField, reqData)) ||
      (thirdField && !this.parseField(thirdField, reqData)) ||
      (fourthField && !this.parseField(fourthField, reqData))
    ) {
      return this.setState({ currentDisabled: false });
    }

    reqData['type'] = EXPORT_METHODS.find(
      (item) => item.id === this.state.currentOption
    ).type;
    reqData['src'] = 0; //src; - sends video only
    reqData['isEnabled'] = true;
    try {
      if (exportRole === 'edit') {
        const response = await axios({
          method: 'put',
          url: EXPORT_APIS.putExport(
            this.state.focusedPrimaryId,
            exportsItemId
          ),
          withCredentials: true,
          data: reqData,
        });
        if (response.status === 200 || response.status === 201) {
          this.props.fetchItems(false, this.state.focusedPrimaryId);

          this.props.showNotification(
            'export',
            `${currentOption.name} export has been changed successfully`
          );

          this.handleHideModalClose(true);
        }
      } else {
        await handleDelete(exportsItemId, true);

        // if export !== type 8 or 7 then we check if we have AMS export

        if (
          this.state.currentOption !== 'OmnistreamLivePlayerRTMP' &&
          this.state.currentOption !== 'OmnistreamLivePlayerSRT' &&
          dashboardConfig.dashboard.exports.useAmsExportConvertAndLogic &&
          (this.props.isFreeTrial || this.props.isBasic || this.props.isLiveOmni)
        ) {
          await this.getAMSExportInfo(
            this.state.focusedPrimaryId,
            reqData.protocol
          );
        }

        const response = await axios({
          method: 'post',
          url:
            this.state.currentOption === 'OmnistreamLivePlayerRTMP'
              ? EXPORT_APIS.generateExportRTMP(this.state.focusedPrimaryId)
              : this.state.currentOption === 'OmnistreamLivePlayerSRT'
              ? EXPORT_APIS.generateExportSRT(this.state.focusedPrimaryId)
              : EXPORT_APIS.setExport(this.state.focusedPrimaryId),
          withCredentials: true,
          data: reqData,
        });
        let fields = this.state.formFields;
        if (response.status === 200 || response.status === 201) {
          let data = response.data['entry'];
          if (currentOption.id === 'SRTListener') {
            //SRT
            this.state.formFields['host'] = data.host;
            this.state.formFields['port'] = data.port;
            this.state.formFields['key'] = data.key;
          } else if (currentOption.id === 'SRTCaller') {
            this.state.formFields['host'] = data.host;
            this.state.formFields['port'] = data.port;
            this.state.formFields['key'] = data.key;
            this.state.formFields['exportStreamId'] = data.exportStreamId;
          } else if (currentOption.id === 'OmnistreamLivePlayerSRT') {
            this.state.formFields['host'] = data.host;
            this.state.formFields['port'] = data.port;
            this.state.formFields['exportStreamId'] = data.exportStreamId;
          } else {
            this.state.formFields['url'] =
              data.protocol +
              '://' +
              data.host +
              (data.port ? ':' + data.port : '') +
              '/' +
              data.endpoint;
            this.state.formFields['key'] = data.key;
          }
        }

        this.setState({ formFields: fields });

        const isExistUserId = isExistUser(this.state.focusedPrimaryId);
        if (!isExistUserId) {
          if (
            this.state.currentOption !== 'OmnistreamLivePlayerRTMP' &&
            this.state.currentOption !== 'OmnistreamLivePlayerSRT'
          ) {
            this.props.handleUpdateCodecType('H.264');
          }

          if (this.state.currentOption === 'OmnistreamLivePlayerRTMP') {
            this.props.handleUpdateCodecType('H.264');
          }

          if (this.state.currentOption === 'OmnistreamLivePlayerSRT') {
            this.props.handleUpdateCodecType('H.265');
          }
        }

        if (
          this.state.currentOption !== 'SRTListener' &&
          this.state.currentOption !== 'OmnistreamLivePlayerRTMP' &&
          this.state.currentOption !== 'OmnistreamLivePlayerSRT'
        ) {
          this.handleHideModalClose(true);
        }
        if (
          (this.state.currentOption === 'SRTListener' ||
            this.state.currentOption === 'OmnistreamLivePlayerRTMP' ||
            this.state.currentOption === 'OmnistreamLivePlayerSRT') &&
          exportRole === 'create-export'
        ) {
          this.props.fetchItems(true, this.state.focusedPrimaryId);
          if (this.state.currentOption === 'OmnistreamLivePlayerSRT') {
            const output = this.props.primaryUsers.find(
              (output) => output.id === this.state.focusedPrimaryId
            );
            if (output.isActive) {
              const nofificationMessage = `${currentOption.name} export has been created successfully`;
              this.props.checkIsExistOmniSRT(
                response.data['entry'],
                nofificationMessage
              );
            } else {
              this.props.showNotification(
                'export',
                `${currentOption.name} export has been created successfully`
              );
            }
          } else {
            this.props.showNotification(
              'export',
              `${currentOption.name} export has been created successfully`
            );
          }
          this.setState({ disabled: true });
        } else if (exportRole === 'create-export') {
          this.props.fetchItems(true, this.state.focusedPrimaryId);
          this.props.showNotification(
            'export',
            `${currentOption.name} export has been created successfully`
          );
        }
      }
    } catch (error) {
      console.log(error.response.data.message);
      this.setState({ currentDisabled: false });
      this.props.showNotification('export', error.response.data.message);
    }
  };

  parseField = (field, reqData) => {
    if (field.id == 'url') {
      let inputUrl = this.state.formFields[field.id];
      if (inputUrl) {
        try {
          inputUrl = inputUrl.replace(/\s/g, '');
          const [protocol, str1] = inputUrl.split('://');
          const [str2, passphrase] = str1.split('?passphrase=');
          const [dst, endpoint] = str2.split('/');
          let [host, port] = dst.split(':');
          port = parseInt(port);
          reqData['protocol'] = protocol;
          reqData['endpoint'] = endpoint;
          reqData['key'] = passphrase ? passphrase : '';
          reqData['host'] = host;
          reqData['port'] = port;
        } catch (error) {
          this.props.showNotification('invalid_url', 'Invalid Url');
          return false;
        }
      }
    } else if (field.id == 'host') {
      let inputHost = this.state.formFields[field.id];
      if (inputHost) {
        reqData['host'] = inputHost;
      }
    } else if (field.id == 'port') {
      let inputPort = this.state.formFields[field.id];
      if (inputPort) {
        if (!isNaN(inputPort)) {
          reqData['port'] = parseInt(inputPort);
        } else {
          this.props.showNotification('invalid_url', 'Invalid port');
          return false;
        }
      }
    } else if (field.id == 'key') {
      let inputKey = this.state.formFields[field.id];
      if (inputKey) {
        reqData['key'] = inputKey;
      } else {
        reqData['key'] = '';
      }
    } else if (field.id == 'exportStreamId') {
      let inputStreamId = this.state.formFields[field.id];
      if (inputStreamId) {
        reqData['exportStreamId'] = inputStreamId;
      } else {
        reqData['exportStreamId'] = '';
      }
    }
    return true;
  };

  isEmptyObject = (obj) => {
    for (let i in obj) {
      if (obj.hasOwnProperty(i)) {
        return false;
      }
    }
    return true;
  };

  checkRequiredFields = (currentOption) => {
    if (
      currentOption === 'SRTListener' ||
      currentOption === 'OmnistreamLivePlayerRTMP' ||
      currentOption === 'OmnistreamLivePlayerSRT'
    ) {
      return;
    } else {
      const { formFields } = this.state;
      if (this.isEmptyObject(formFields)) {
        return;
      }
      const exportFields = EXPORT_METHODS.find(
        (method) => method.id === currentOption
      ).fields.filter((field) => field.required === true);

      if (exportFields.length === 1) {
        if (formFields[exportFields[0].id]) {
          if (this.state.disabled !== false) {
            this.setState({ disabled: false });
          }
        } else {
          if (this.state.disabled !== true) {
            this.setState({ disabled: true });
          }
        }
      } else {
        let isFill = [];
        exportFields.forEach((elem) => {
          if (formFields[elem.id]) {
            isFill.push(true);
          } else {
            isFill.push(false);
          }
        });
        const reducer = (previousValue, currentValue) =>
          previousValue && currentValue;
        const isFillAllFields = isFill.reduce(reducer);
        if (isFillAllFields) {
          if (this.state.disabled !== false) this.setState({ disabled: false });
        } else {
          if (this.state.disabled !== true) this.setState({ disabled: true });
        }
      }
    }
  };

  render() {
    return (
      <div
        className="modal fade"
        ref={(modal) => (this.modal = modal)}
        id="modal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="modalLabel"
        aria-hidden="true"
      >
        <div id="modal_dialog" className="modal-dialog" role="document">
          <div id="modal_content" className="modal-content">
            {this.renderBodyModal()}
          </div>
        </div>
      </div>
    );
  }
}

export default Modal;
