import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import './SettingsModal.css';

import Modal from '../common/Modal/Modal';
import InputField from '../common/InputField/InputField';
import Button from '../common/Button/Button';

//--------------- SettingsModal.js local constants ---------------//
const PRIMARY_NAME_MAX_LENGTH = 15;
const OUTPUT_NAME_MAX_LENGTH = 12;
//-------------------------------------------------------//

class SettingsModal extends React.PureComponent {
  state = {
    displayedName: '',
    isDesignatedOutput: '',
    isDesignatedOutputEdited: false,
    showErrorMessage: false,
    maxNameLength: '',
  };

  componentDidUpdate(prevProps) {
    const { type, displayedName, isDesignatedOutput } = this.props;
    if (type !== prevProps.type) {
      const maxNameLength = type === 'Primary' ? PRIMARY_NAME_MAX_LENGTH : OUTPUT_NAME_MAX_LENGTH;
      this.setState({ maxNameLength });
    }

    if (displayedName !== prevProps.displayedName) {
      this.setState({ displayedName });
    }

    if (isDesignatedOutput !== prevProps.isDesignatedOutput) {
      this.setState({ isDesignatedOutput });
    }
  }

  handleInputChange = (e) => {
    const value = e.target.value;
    if (value.length <= this.state.maxNameLength) {
      this.setState({
        displayedName: value,
        showErrorMessage: false,
      });
    } else {
      this.setState({ showErrorMessage: true });
    }
  };

  handleCheckboxChange = (e) => {
    this.setState({
      isDesignatedOutput: e.target.checked,
      isDesignatedOutputEdited: true,
    });
  };

  handleSaveClick = () => {
    const { type, id, setDisplayedName, setDesignatedOutput } = this.props;

    this.setState({
      displayedName: '',
      showErrorMessage: false,
    });

    setDisplayedName(type, id, this.state.displayedName);

    if (type === 'Output' && this.state.isDesignatedOutputEdited === true) {
      setDesignatedOutput(id, this.state.isDesignatedOutput);

      this.setState({
        isDesignatedOutput: '',
        isDesignatedOutputEdited: false,
      });
    }
  };

  render() {
    const { type, name, uuid, handleGuidCopyIconClick } = this.props;
    const { displayedName, isDesignatedOutput, showErrorMessage } = this.state;

    let headerTitle;
    if (type === 'Primary') {
      headerTitle = `"${name}" display name`;
    } else if (type === 'Output') {
      headerTitle = `"${name}" settings`;
    }

    return (
      <Modal
        uniqueId="setting-displayed-name-modal"
        type={this.props.type}
        headerTitle={headerTitle}
        buttons={[{ name: 'Save', className: 'info', clickHandler: this.handleSaveClick }]}
        bodyClass="modal-body modal-body-viewer">
        <InputField
          uniqueId="displayed-name-input"
          labelName="Display name:"
          type="text"
          value={displayedName}
          changeHandler={this.handleInputChange}
        />
        <div className={`text-danger ${showErrorMessage ? 'visible' : 'invisible'}`}>
          <strong>The name is too long</strong> (maximum {this.state.maxNameLength} characters)
        </div>

        {type === 'Output' && (
          <Fragment>
            <div className="custom-control custom-checkbox mb-3">
              <input
                type="checkbox"
                className="custom-control-input"
                id="designatedOutputCheckbox"
                checked={isDesignatedOutput}
                onChange={this.handleCheckboxChange}
              />
              <label className="custom-control-label" htmlFor="designatedOutputCheckbox">
                Designated output
              </label>
            </div>
            <div className="d-flex justify-content-between align-items-center">
              <div className="mr-3">
                GUID: <strong>{uuid}</strong>
              </div>
              <Button iconName="file_copy" clickHandler={() => handleGuidCopyIconClick(uuid)} />
            </div>
          </Fragment>
        )}
      </Modal>
    );
  }
}

SettingsModal.propTypes = {
  type: PropTypes.string.isRequired,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  name: PropTypes.string.isRequired,
  displayedName: PropTypes.string.isRequired,
  isDesignatedOutput: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired,
  uuid: PropTypes.string.isRequired,
  setDisplayedName: PropTypes.func.isRequired,
  handleGuidCopyIconClick: PropTypes.func.isRequired,
  setDesignatedOutput: PropTypes.func.isRequired,
  companyType: PropTypes.number,
};

export default SettingsModal;
