import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import $ from 'jquery';

import './InfoHeader.css';

import LightIndicator from '../LightIndicator/LightIndicator';
import AlarmIcon from '../common/AlarmIcon/AlarmIcon';

class InfoHeader extends React.PureComponent {
  fadeTime = 200;
  uniqueId = `${this.props.type}-${this.props.id}-card-icons`;

  componentDidMount() {
    $(`#${this.uniqueId}`).fadeOut(0);
    this.addDisconnectedOutputTooltip();
  }

  componentDidUpdate(prevProps) {
    const { isBroadcasting, outputAddressId } = this.props;
    if (
      isBroadcasting !== prevProps.isBroadcasting ||
      outputAddressId !== prevProps.outputAddressId
    ) {
      if (isBroadcasting === true && outputAddressId !== null) {
        this.addDisconnectedOutputTooltip();
      } else {
        $(`#${this.props.name}`).tooltip('hide');
      }
    }
  }

  addDisconnectedOutputTooltip() {
    $(`#${this.props.name}`).tooltip({
      title: 'This source is not connected to an output',
    });
  }

  openSettingDisplayedNameModal = () => {
    const { type, id, name, displayedName, isDesignatedOutput, uuid } = this.props;

    this.props.setDetailsForModal(type, id, name, displayedName, isDesignatedOutput, uuid);

    $('#setting-displayed-name-modal').modal();
  };

  handleMouseEnter = () => {
    $(`#${this.uniqueId}`).fadeIn(this.fadeTime);
  };

  handleMouseLeave = () => {
    $(`#${this.uniqueId}`).fadeOut(this.fadeTime);
  };

  render() {
    const {
      type,
      name,
      displayedName,
      isActive,
      isBroadcasting,
      isThereIssue,
      uuid,
      screenSize,
      handleGuidCopyIconClick,
      outputAddressId,
      daysToExpire,
      companyType,
    } = this.props;
    let expirationMessage = '';
    const expiration = daysToExpire + 3; //add 3 days grace time as in central
    if (expiration < 0) {
      expirationMessage = 'License expired';
    } else if (expiration <= 10) {
      const days = Math.floor(expiration) + 1;
      expirationMessage = `License will expire in ${days} ${days <= 1 ? 'day' : 'days'}`;
    }
    return (
      <div
        className="info-header d-flex justify-content-between align-items-center"
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}>
        <span className="d-flex align-items-center">
          <span className="mr-auto">{displayedName ? displayedName : name}</span>
          <span id={this.uniqueId}>
            {type === 'Primary' && (
              <i
                className="primary-name-edit-icon material-icons"
                onClick={this.openSettingDisplayedNameModal}>
                edit
              </i>
            )}
            {type === 'Output' && (
              <Fragment>
                <i
                  className="output-settings-icon material-icons"
                  onClick={this.openSettingDisplayedNameModal}>
                  settings_applications
                </i>
                <i
                  className="guid-copy-icon material-icons ml-2"
                  onClick={() => handleGuidCopyIconClick(uuid)}>
                  file_copy
                </i>
              </Fragment>
            )}
          </span>
        </span>
        <span className="status-icon mr-1 d-flex align-items-center">
          {type === 'Primary' && (
            <Fragment>
              {companyType === 0 && (
                <div
                  // className="mr-4 mb-4"
                  hidden={isBroadcasting === false || outputAddressId !== null}>
                  <AlarmIcon
                    position="relative"
                    tooltipDirection="down"
                    alarmTooltipText="This source is not connected to an output"
                    alarmIcon="broken_image"
                    criticalLevel={0}
                  />
                </div>
              )}

              <div
                // className="mr-4 mb-4"
                hidden={isNaN(daysToExpire) || daysToExpire > 10}>
                <AlarmIcon
                  position="relative"
                  tooltipDirection="down"
                  alarmTooltipText={expirationMessage}
                  alarmIcon="access_time"
                  isIconBold={true}
                  criticalLevel={daysToExpire < 0 ? 2 : 0}
                />
              </div>
            </Fragment>
          )}
          <LightIndicator
            type={type}
            isActive={isActive}
            isBroadcasting={isBroadcasting}
            isThereIssue={isThereIssue}
            screenSize={screenSize}
          />
        </span>
      </div>
    );
  }
}

InfoHeader.propTypes = {
  type: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  displayedName: PropTypes.string.isRequired,
  uuid: PropTypes.string,
  isDesignatedOutput: PropTypes.bool,
  setDetailsForModal: PropTypes.func.isRequired,
  handleGuidCopyIconClick: PropTypes.func,
};

export default InfoHeader;
