import config from '../dashboard_config.json';

// const protocol = 'http';
const protocol = 'https';

const hostname = process.env.REACT_APP_HOSTNAME;

export const APIs = {
  login: `${protocol}://${hostname}/controllerLoginHandler`,

  getControllerInfo: `${protocol}://${hostname}/controller/getInfo`,

  getBroadcastThumbnail(primaryId) {
    return `${protocol}://${hostname}/controller/primaries/${primaryId}/getImage`;
  },

  associatePrimaryWithOutput: `${protocol}://${hostname}/controller/primaries/setBroadcast`,
  setRecordsDesignated: `${protocol}://${hostname}/controller/outputs/setDesignated`,

  modifySocialMediaSettings(primaryId) {
    return `${protocol}://${hostname}/controller/primaries/${primaryId}/rtmp`;
  },

  logout: `${protocol}://${hostname}/logout`,
};

export const webSocketEndPoint = `wss://${hostname}/matrix/`;

export const EXPORT_APIS = {
  getExportList(primaryId) {
    return `${protocol}://${hostname}/controller/primaries/${primaryId}/exports`;
  },

  setExport(primaryId) {
    return `${protocol}://${hostname}/controller/primaries/${primaryId}/exports`;
  },

  deleteExport(primaryId, exportsItemId) {
    return `${protocol}://${hostname}/controller/primaries/${primaryId}/exports/${exportsItemId}`;
  },

  putExport(primaryId, exportsItemId) {
    return `${protocol}://${hostname}/controller/primaries/${primaryId}/exports/${exportsItemId}`;
  },

  deleteAllExports(primaryId) {
    return `${protocol}://${hostname}/controller/primaries/${primaryId}/exports`;
  },

  generateExportRTMP(primaryId) {
    return `${protocol}://${hostname}/controller/primaries/${primaryId}/exports/generate`;
  },

  generateExportSRT(primaryId) {
    return `${protocol}://${hostname}/controller/primaries/${primaryId}/exports/generate?type=srt`;
  },

  getExportLabels(primaryId) {
    return `${protocol}://${hostname}/controller/primaries/${primaryId}/exports/labels`;
  },

  setExportLabel(primaryId, exportsItemId) {
    return `${protocol}://${hostname}/controller/primaries/${primaryId}/exports/${exportsItemId}/label`;
  },

  putExportLabel(primaryId, exportsItemId) {
    return `${protocol}://${hostname}/controller/primaries/${primaryId}/exports/${exportsItemId}/label`;
  },

  deleteExportLabel(primaryId, exportsItemId) {
    return `${protocol}://${hostname}/controller/primaries/${primaryId}/exports/${exportsItemId}/label`;
  },
  getAMSExport(primaryId) {
    return `${protocol}://${hostname}/controller/primaries/${primaryId}/amsExport/getAmsExportInfo`;
  },
};
