import React from 'react';
import PropTypes from 'prop-types';
import './NavigationTabsContainer.css';

class NavigationTabsContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.initializeState();
    this.localStorageKey = this.props.tabsContainerId + 'TabsStateV1';
    this.focusedPrimaryId = this.props.focusedPrimaryId;
  }

  componentDidMount() {
    if (this.localStorageKey === 'broadcastSettingsTabsStateV1') {
      const tabsState = {
        Export: { isDisplayed: false },
        records: { isDisplayed: false },
        'settings-control': { isDisplayed: true },
      };
      localStorage.setItem(this.localStorageKey, JSON.stringify(tabsState));
      this.setState(tabsState);
    } else {
      if (localStorage.getItem(this.localStorageKey) !== null) {
        const tabsState = JSON.parse(localStorage.getItem(this.localStorageKey));
        this.setState(tabsState);
      } else {
        this.saveTabsStateToLocalStorage();
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    this.state = this.updateState();
    this.focusedPrimaryId = this.props.focusedPrimaryId;
    if (this.localStorageKey === 'broadcastSettingsTabsStateV1') {
      if (prevProps.focusedPrimaryId !== this.focusedPrimaryId) {
        const tabsState = {
          Export: { isDisplayed: false },
          records: { isDisplayed: false },
          'settings-control': { isDisplayed: true },
        };
        localStorage.setItem(this.localStorageKey, JSON.stringify(tabsState));
        this.setState(tabsState);
      }
    }
  }

  initializeState = () => {
    let initialTabsState = {};
    this.props.children.forEach((childComponent, i) => {
      if (childComponent.props) {
        initialTabsState[childComponent.props.componentId] = {
          isDisplayed: i === 0,
        };
      }
    });

    return initialTabsState;
  };

  handleTabsSwitch = (e) => {
    let tabsState = { ...this.state };
    for (let tabId in tabsState) {
      tabsState[tabId].isDisplayed = tabId === e.target.id;
    }

    this.setState(tabsState, () => this.saveTabsStateToLocalStorage());
  };

  saveTabsStateToLocalStorage = () => {
    localStorage.setItem(this.localStorageKey, JSON.stringify(this.state));
    if (this.state['Export']) {
      if (this.state['Export'].isDisplayed) {
        this.props.setExportDisplay(true);
      } else {
        this.props.setExportDisplay(false);
      }
    }
  };

  updateState() {
    let isAlarm = false;
    let isAlert = false;
    let tabsState = this.state;
    this.props.children.forEach((childComponent, i) => {
      if (childComponent && childComponent.props.componentId === 'alarms') {
        if (childComponent.props.alarmsVideo && childComponent.props.alarmsVideo.length > 0) {
          for (let i = 0; i < childComponent.props.alarmsVideo.length; i++) {
            if (childComponent.props.alarmsVideo[i].type === 'ERROR') {
              isAlarm = true;
            } else {
              isAlert = true;
            }
          }
        }
        if (childComponent.props.alarmsPrimary && childComponent.props.alarmsPrimary.length > 0) {
          for (let i = 0; i < childComponent.props.alarmsPrimary.length; i++) {
            if (childComponent.props.alarmsPrimary[i].type === 'ALARM') {
              isAlarm = true;
            } else {
              isAlert = true;
            }
          }
        }

        tabsState[childComponent.props.componentId].pendingAlarms = isAlarm;
        tabsState[childComponent.props.componentId].pendingAlerts = isAlert;
      } /*else if (childComponent.props.componentId === 'logs') {
				tabsState[childComponent.props.componentId].pending = (childComponent.props.logs && childComponent.props.logs.length > 0);
			}*/
    });
    return tabsState;
  }

  render() {
    const { tabsContainerClass, children } = this.props;

    return (
      <div className={tabsContainerClass}>
        <ul className="nav nav-tabs border-bottom-0 tab-activate">
          {children.map(
            ({ props }) =>
              props && (
                <li key={props.componentId} className="nav-item active_tab">
                  <button
                    className={`nav-link ${
                      this.state[props.componentId].isDisplayed && 'active'
                    } tab-pointer btn-icon`}
                    id={props.componentId}
                    onClick={this.handleTabsSwitch}>
                    <i
                      className={`center-icon material-icons ${
                        props.componentId === 'alarms' &&
                        this.state[props.componentId].pendingAlarms
                          ? 'icon-pending-alarms'
                          : this.state[props.componentId].pendingAlerts
                          ? 'icon-pending-alerts'
                          : ''
                      }`}>
                      {props.isExistExport && <span className="is-exist-export"></span>}
                      {props.tabIcon}
                    </i>
                    {props.tabName}
                  </button>
                </li>
              ),
          )}
        </ul>

        <div className="bg-light data-area records-scrollbar">
          {children.map(
            (childComponent) =>
              childComponent && (
                <div
                  key={childComponent.props.componentId}
                  className={
                    this.state[childComponent.props.componentId].isDisplayed
                      ? 'd-block export-list-option'
                      : 'd-none'
                  }>
                  {childComponent}
                </div>
              ),
          )}
        </div>
      </div>
    );
  }
}

NavigationTabsContainer.propTypes = {
  tabsContainerId: PropTypes.string.isRequired,
  tabsContainerClass: PropTypes.string.isRequired,
  children: PropTypes.array.isRequired,
};

export default NavigationTabsContainer;
