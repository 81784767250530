import React from 'react';
import PropTypes from 'prop-types';
import GoogleMapReact from 'google-map-react';
// import { fitBounds } from 'google-map-react/utils';

import './Map.css';

import { GOOGLE_API_KEY } from '../../constants/apiKeys';

import MapMarker from '../MapMarker/MapMarker';
import MapCard from '../MapCard/MapCard';

class Map extends React.PureComponent {
	state = {
		mapCenter: {
			lat: 0,
			lng: 0
		},
		mapZoom: 14
	};

	markerIcon = './images/green_light_mini.png';
	markerIconBroadcasting = './images/red_light_mini.png';

	_iconScheduler;

	componentDidMount() {
		if ('geolocation' in navigator) {
			navigator.geolocation.getCurrentPosition((position) => {
				const { latitude, longitude } = position.coords;
				const mapCenter = {
					lat: latitude,
					lng: longitude
				};
				this.setState({ mapCenter });
			});
		}

		// let index = 0;
		// this._iconScheduler = setInterval(() => {
		// 	index = index < 3 ? index + 1 : 0;
		// 	this.markerIconBroadcasting = `./images/omnistream_icon_${index}.png`;

		// 	// if ('geolocation' in navigator) {
		// 	// 	navigator.geolocation.getCurrentPosition((position) => {
		// 	// 		const { latitude, longitude } = position.coords;
		// 	// 		const mapCenter = {
		// 	// 			lat: latitude,
		// 	// 			lng: longitude
		// 	// 		};
		// 	// 		// if (mapCenter.lat !== this.state.mapCenter.lat || mapCenter.lng !== this.state.mapCenter.lng){
		// 	// 			this.setState({ mapCenter });
		// 	// 		// }
		// 	// 	});
		// 	// }
		// }, 3000);
	}

	componentWillUnmount() {
		clearInterval(this._iconScheduler);
	}

	handleShowAllPrimaries = () => {
		const loggedInPrimaryUsers = this.props.primaryUsers.filter(
			(primary) => primary.isActive
		);

		if (loggedInPrimaryUsers.length === 1) {
			this.handleLocatePrimary(
				loggedInPrimaryUsers[0].latitude,
				loggedInPrimaryUsers[0].longitude
			);

			return;
		}

		let points = [];
		loggedInPrimaryUsers.forEach((primary) => {
			points.push({
				lat: primary.latitude,
				lng: primary.longitude
			});
		});

		// For some reason LatLngBounds() return object with weird properties names,
		// thus the temporary variable name
		const boundsWithWeirdPropertiesNames = new window.google.maps
			.LatLngBounds();

		for (let i = 0; i < points.length; i++) {
			boundsWithWeirdPropertiesNames.extend(points[i]);
		}
		// Due to the above weirdness next code block translates
		// the weird names to normal so fitBounds() will work
		const bounds = {
			nw: {
				lat: boundsWithWeirdPropertiesNames.Ya.i,
				lng: boundsWithWeirdPropertiesNames.Ua.i
			},
			se: {
				lat: boundsWithWeirdPropertiesNames.Ya.j,
				lng: boundsWithWeirdPropertiesNames.Ua.j
			}
		};

		const size = {
			width: Math.max(
				document.documentElement.clientWidth,
				window.innerWidth || 0
			),
			height: Math.max(
				document.documentElement.clientHeight,
				window.innerHeight || 0
			)
		};

		// const { center, zoom } = fitBounds(bounds, size);

		// this.setState({
		// 	mapCenter: center,
		// 	mapZoom: zoom
		// });
	};

	handleLocatePrimary = (lat, lng) => {
		const mapCenter = { lat, lng };
		this.setState({
			mapCenter,
			// mapZoom: 19
		});
	};

	onPositionChanged = (changeEventValue) => {
		console.log(changeEventValue)
		const mapCenter = changeEventValue.mapCenter;
		this.setState({
			mapCenter,
			// mapZoom: 19
		});
	}

	render() {
		const {
			primaryUsers,
			focusedPrimaryId,
			screenSize,
			handlePrimaryFocus,
			clickIFBmouseDown,
	
		} = this.props;

		const activePrimaryUsers = primaryUsers.filter(
			(primaryUser) => primaryUser.isActive
		);

		const sortedPrimaryUsers = primaryUsers.sort(
			(obj1, obj2) => obj1.id - obj2.id
		);

		return (
			<div className="map">
				<div id="map-container">
					<GoogleMapReact
						bootstrapURLKeys={{ key: GOOGLE_API_KEY }}
						center={this.state.mapCenter}
						zoom={this.state.mapZoom}
						onChange={this.onPositionChanged}
					>
						{activePrimaryUsers.map(
							({
								id,
								latitude,
								longitude,
								isBroadcasting,
								displayedName,
								username
							}) => (
								<MapMarker
									key={id}
									lat={latitude}
									lng={longitude}
									imgSrc={
										isBroadcasting ? (
											this.markerIconBroadcasting
										) : (
											this.markerIcon
										)
									}
									primaryName={
										displayedName ? displayedName : username
									}
								/>
							)
						)}
					</GoogleMapReact>
				</div>
				<div className="map-cards-section">
	
					<div id="map-accordion">
						{sortedPrimaryUsers.map(
							({
								id,
								displayedName,
								username,
								isActive,
								isBroadcasting,
								latitude,
								longitude
							}) => (
								<MapCard
									key={id}
									primaryId={id}
									primaryName={
										displayedName ? displayedName : username
									}
									isActive={isActive}
									isBroadcasting={isBroadcasting}
									focusedPrimaryId={focusedPrimaryId}
									screenSize={screenSize}
									handlePrimaryFocus={handlePrimaryFocus}
									clickIFBmouseDown={clickIFBmouseDown}
				
									locatePrimary={() =>
										this.handleLocatePrimary(latitude, longitude)}
								/>
							)
						)}
					</div>
				</div>
			</div>
		);
	}
}

Map.propTypes = {
	primaryUsers: PropTypes.array.isRequired
};

export default Map;
