import React from 'react';
import PropTypes from 'prop-types';

import './InputField.css';

const InputField = ({
	uniqueId,
	labelName,
	labelTextWeight,
	type,
	value,
	changeHandler,
	isDisabled,
	reference
}) => (
	<div className="text-input form-input">
		<label
			className={`font-weight-${labelTextWeight === 'bold'
				? 'bold'
				: 'normal'}`}
			htmlFor={uniqueId}
		>
			{labelName}
		</label>
		<input
			id={uniqueId}
			className="form-control"
			type={type}
			value={value}
			onChange={changeHandler}
			disabled={isDisabled}
			ref={reference}
		/>
	</div>
);

InputField.propTypes = {
	uniqueId: PropTypes.string.isRequired,
	labelName: PropTypes.string.isRequired,
	labelTextWeight: PropTypes.string,
	type: PropTypes.string.isRequired,
	value: PropTypes.string.isRequired,
	changeHandler: PropTypes.func.isRequired,
	isDisabled: PropTypes.oneOf([ undefined, false, true ]),
	reference: PropTypes.object
};

export default React.memo(InputField);
